// dateUtils.js
export function formatDate(dateString) {
    // Parse the date string
    const date = new Date(dateString);
    
    // Create arrays for days and months in Dutch
    const days = ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'];
    const months = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];

    // Get the components
    const dayOfWeek = days[date.getUTCDay()];
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    
    // Convert 24-hour time to 15:00 format
    const hours = (date.getUTCHours() + 2) % 24; // Add 2 hours to convert from UTC to the desired timezone
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    
    // Format the date string
    return `${dayOfWeek}, ${day} ${month} - ${hours}:${minutes}`;
}