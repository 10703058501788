import React from "react";
import styles from "./Hero.module.scss";

export default function Hero({ title, subtitle, image }) {
    return (
        <div className={styles.background} style={{ '--bg-image': `url(${image})` }}>
            <div className={styles.headline}>
                <h2>{subtitle}</h2>
                <h1>{title}</h1>
            </div>
        </div>
    );
}
