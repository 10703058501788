import React from 'react'
// graphql
import { useQuery } from '@apollo/client';
import { GET_PAGE_DATA, GET_SEASON_RESULTS } from '../../graphql/queries';
// pages
import Error from '../Error';
// helmet
import { Helmet } from 'react-helmet';
// components
import Loading from '../../components/Elements/Loading';
import Result from '../../components/Basics/Result';
// utils
import { formatDate } from '../../utils/dateUtils';

export default function Uitslagen() {
  const slug = 'uitslagen';

  // --------- QUERY FOR THE PAGE DATA --------- //

  const { loading, error, data } = useQuery(GET_PAGE_DATA, {
    variables: { slug },
  });
  // Check if data is loaded before accessing seasonSlug
  const seasonSlug = data?.page?.season?.slug;

  // Second query should only be executed if seasonSlug is available
  const { loading: loadingResults, error: errorResults, data: dataResults } = useQuery(GET_SEASON_RESULTS, {
    variables: { seasonSlug },
    skip: !seasonSlug, // Skip this query if seasonSlug is not available
  });
  if (loading || loadingResults) return <Loading />;
  if (error || errorResults) return <Error code='502' text='Invalid response. Please try again later.'/>;

  // --------- PAGE DATA --------- //

  const pageData = data.page;

  // --------- RENDER --------- //

  return (
    <>
      {/* head data */}
      <Helmet>
        <title>{pageData.title}</title>
        <meta name='description' content={pageData.pageDescription}/>
      </Helmet>

      {/* last results of a match */}
      <div className='container container--top'>
        <h1 className='container__header'>Uitslagen</h1>
        <div className='container__header'><h3>{pageData.season.title}</h3></div>
        <div className='results'>
          {dataResults.results.map(result => {
            const matchDate = formatDate(result.matchDate);
            return <Result key={result.id} homeTeam={result.homeTeam.title} awayTeam={result.awayTeam.title} homeTeamScore={result.homeTeamScore} awayTeamScore={result.awayTeamScore} matchDate={matchDate} competition={result.competition.title} comment={result.comment} />
          })}
        </div>
      </div>
    </>
  )
}
