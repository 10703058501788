import React, { useEffect, useState } from 'react'
import styles from './Header.module.scss'
import Navigation from '../Navigation'
import Hamburger from '../../Basics/Hamburger'
import Logo from '../../Basics/Logo/Logo'

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => {
    // only do this when the screen is smaller than 1000px and the hamburgermenu appears
    if (window.innerWidth <= 1000) {
      setMenuOpen(!menuOpen)
    }
  };

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [menuOpen]);

  return (
    <header className={styles.header}>
      <div className={styles.header__container}>
        <div className={styles.header__logo}>
          <Logo />
        </div>
        <div className={styles.hamburger} onClick={toggleMenu}>
          {menuOpen ? <Hamburger menuStatus={'open'}/> : <Hamburger/>}
        </div>
        <div className={`${styles.operations} ${menuOpen ? styles.open : ''}`}>
          <Navigation closeMenu={toggleMenu} />
        </div>
      </div>
    </header>
  )
}
