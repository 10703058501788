import React from 'react'
import styles from './Footer.module.scss'
import Logo from '../../Basics/Logo/Logo'
import RBFA from '../../../assets/images/rbfa.svg'
import StadAalst from '../../../assets/images/stad-aalst.svg'
import VoetbalVlaanderen from '../../../assets/images/voetbal-vlaanderen.svg'
import Facebook from '../../../assets/images/facebook.svg'
import Instagram from '../../../assets/images/instagram.svg'
import { Link } from 'react-router-dom'
import Navigation from '../Navigation'

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__container}>
        <div className={styles.footer__section}>
          <Logo />
          <div className={styles.footer__socials}>
            <Link to="https://www.facebook.com/p/RFC-Doggen-100057054506591/" target="_blank">
              <img src={Facebook} alt="Facebook" />
            </Link>
            <Link to="https://www.instagram.com/fcdoggenerembodegem/" target="_blank">
              <img src={Instagram} alt="Instagram" />
            </Link>
          </div>
        </div>
        <div className={styles.footer__section}>
          <h3>Sitemap</h3>
          <Navigation addClassName={styles.footer__sitemap} />
        </div>
        <div className={styles.footer__section}>
          <h3>Links</h3>
          <div className={styles.footer__links}>
            <Link className={styles.footer__link} to="https://www.rbfa.be/" target="_blank">
              <img src={RBFA} alt="RBFA" />
            </Link>
            <Link className={styles.footer__link} to="https://www.voetbalvlaanderen.be/" target="_blank">
              <img src={VoetbalVlaanderen} alt="Voetbal Vlaanderen" />
            </Link>
            <Link className={styles.footer__link} to="https://www.aalst.be/" target="_blank">
              <img src={StadAalst} alt="Stad Aalst" />
            </Link>
          </div>
        </div>
        <div className={styles.footer__section}>
          <div className={styles.footer__info}>
            <h3>FC Doggen Erembodegem</h3>
            <p>Resschebeke 50, 9320 Erembodegem</p>
            <div>
              <p>Lokaal (Café Sint Jans-Kring, Bij Bartje):</p>
              <p>Rerum Novarumstraat 4, 9300 Aalst</p>
            </div>
            <div>
              <p>GC + Secretaris:</p>
              <Link to='mailto:rene.vdb@proximus.be'>rene.vdb@proximus.be</Link>
            </div>
          </div>
          <div className={styles.footer__copyright}>
            <p>© 2024 F.C. Doggen Erembodegem</p>
            <p>Made with passion by <Link className={styles.benoit} to='https://benoitbiraguma.onrender.com' target='_blank'>benoitbiraguma</Link> </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
