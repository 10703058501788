import React from 'react'
import styles from './Button.module.scss'
import { Link } from 'react-router-dom'

export default function Button({ label, link, target }) {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Link className={styles.button} to={link} target={target} onClick={scrollToTop}>
      <span className={styles.button__label}>{label}</span>
    </Link>
  )
}
