import React from 'react'
import { useRouteError } from 'react-router-dom'
// helmet
import { Helmet } from 'react-helmet';
// components
import Header from '../../components/Elements/Header';
import Footer from '../../components/Elements/Footer';

export default function Error({ code, text }) {
  const error = useRouteError();
return (
  <>
    <Helmet>
      <title>Foutmelding | FC Doggen Erembodegem</title>
      <meta name='description' content='Foutmelding' />
    </Helmet>

    {error ? <Header /> : null}
    <div className='container container--top'>
      <h1>Foutmelding - <span className='focus'>{error ? error.status : code}</span></h1>
      <div>Volgende error heeft zich voorgedaan: <span className='focus'>{error ? error.statusText : text}</span></div>
    </div>
    {error ? <Footer /> : null}
  </>
)
}
