import React from 'react'
// graphql
import { useQuery } from '@apollo/client';
import { GET_PAGE_DATA } from '../../graphql/queries';
// styles
import './Contact.scss'
// pages
import Error from '../Error';
// helmet
import { Helmet } from 'react-helmet';
// components
import Loading from '../../components/Elements/Loading';
import { Link } from 'react-router-dom';
// documents
import aangifte from '../../assets/documents/insurance_blanco_attest_template_nl.pdf';
import Button from '../../components/Basics/Button';

export default function Contact() {
  const slug = 'contact';

  // --------- QUERY FOR THE PAGE DATA --------- //

  const { loading, error, data } = useQuery(GET_PAGE_DATA, {
    variables: { slug },
  });
  if (loading) return <Loading />;
  if (error) return <Error code='502' text='Invalid response. Please try again later.'/>;

  // --------- PAGE DATA --------- //

  const pageData = data.page;
  const heroSection = pageData.heroSection;

  // --------- RENDER --------- //

  return (
  <>
    {/* head data */}
    <Helmet>
      <title>{pageData.title}</title>
      <meta name='description' content={pageData.pageDescription}/>
    </Helmet>

    <div className='container container--top'>
      <h1 className='container__header'>Contact</h1>

      {/* contactpersonen */}
      <div className="container__header">
        <h3>Contactpersonen</h3>
      </div>
      <div className="two-parts contact">
        <div className="contact__persons">
          <div className="person">
            <p className='person__title'>Gerechtigde Correspondent en secretaris</p>
            <div>
              <p className='person__field'>Naam</p>
              <p>René Van Den Broeck</p>
            </div>
            <div>
              <p className='person__field'>Telefoon</p>
              <Link to='tel:0472953859'>0472/953859</Link>
            </div>
            <div>
              <p className='person__field'>Email</p>
              <Link to='mailto:rene.vdb@proximus.be'>rene.vdb@proximus.be</Link>
            </div>
          </div>
          <div className="person">
            <p className='person__title'>Sportief verantwoordelijke</p>
            <div>
              <p className='person__field'>Naam</p>
              <p>Thomas Van Damme</p>
            </div>
            <div>
              <p className='person__field'>Telefoon</p>
              <Link to='tel:0498108718'>0486/720050</Link>
            </div>
            <div>
              <p className='person__field'>Email</p>
              <Link to='mailto:jordydesmet@telenet.be'>tovarisj@hotmail.com</Link>
            </div>
          </div>
          <div className="person">
            <p className='person__title'>Aanspreekpunt Integriteit (Api)</p>
            <div>
              <p className='person__field'>Naam</p>
              <p>Jordy De Smet</p>
            </div>
            <div>
              <p className='person__field'>Telefoon</p>
              <Link to='tel:0498847609'>0498/847609</Link>
            </div>
            <div>
              <p className='person__field'>Email</p>
              <Link to='mailto:jordydesmet@telenet.be'>jordydesmet@telenet.be</Link>
            </div>
          </div>
        </div>
        <div className="box contact__image">
          <img src={heroSection.image.url} alt="contact" />
        </div>
      </div>

      {/* aangifte ongeval */}
      <div className="container__header">
        <h3>Aangifte ongeval</h3>
      </div>
      <div className="accident">
        <p className='paragraph'>Wat moet ik doen bij een ongeval op een voetbalveld?</p>
        <p className='paragraph'>Zodra de eerste hulp is verleend en/of de noodsituatie voorbij is, neem je zo snel mogelijk contact op met de gerechtigd correspondent (GC) van jouw club.</p>
        <p className='paragraph'>Twee mogelijkheden:</p>
        <p className='paragraph'>De GC voert het ongeval rechtstreeks in ons E-Kickoff platform in en geeft je het medisch attest dat moet worden ingevuld en ondertekend door de arts + handtekening en kleefbriefje van het ziekenfonds van de speler.
        Je overhandigt het aangifteformulier en het naar behoren ingevuld en ondertekend medisch attest zo spoedig mogelijk aan de GC voor verdere afhandeling.</p>
        <p className='paragraph'>Belangrijk</p>
        <p className='paragraph'>Je GC moet de aangifte binnen 21 dagen na het ongeval indienen.
  Alle communicatie (ontvangstbevestiging, terugbetalingsbewijs, ...)
  verloopt via de GC van de club.
  Kinesitherapiesessies: indien niet vermeld op het medisch attest of indien bijkomende sessies worden gevraagd: de speler of de club moet ons vóór het
  begin van elke nieuwe reeks een kopie van het medisch voorschrift toezenden.</p>
        <p className='paragraph'>Hieronder staat een kopie van het aangifteformulier.</p>
        <Button label='Download aangifteformulier' link={aangifte} target='_blank' />
      </div>

      {/* adressen */}
      <div className="container__header">
        <h3>Adressen</h3>
      </div>
      <p className='paragraph'><strong>Terrein:</strong> Resschebeke 50, 9320 Erembodegem</p>
      <p className='paragraph'><strong>Lokaal:</strong> Rerum Novarumstraat 4, 9300 Aalst (Café Sint Jans-Kring, Bij Bartje)</p>

      {/* disclaimer */}
      <div className="container__header">
        <h3>GDPR</h3>
      </div>
      <p className='paragraph'>De foto's die op deze website worden weergegeven zijn geplaatst met de uitdrukkelijke toestemming van de betrokken personen. In overeenstemming met de GDPR (Algemene Verordening Gegevensbescherming) zijn alle spelers/bestuursleden/staff/medewerkers geïnformeerd over het gebruik van hun foto's op deze website, en zij hebben hiervoor hun toestemming gegeven. Mocht u om welke reden dan ook van mening zijn dat uw afbeelding onterecht is geplaatst of als u uw toestemming wilt intrekken, neem dan contact met ons op via bovenstaande contactgegevens. We zullen uw verzoek zo snel mogelijk verwerken en de betreffende afbeelding verwijderen.</p>
    </div>
  </>
  )
}
