import React from 'react'
import styles from './Result.module.scss'

export default function Result({ homeTeam, awayTeam, homeTeamScore, awayTeamScore, matchDate, competition, comment }) {
  return (
    <div className={styles.result}>
      <div className={styles.result__home}><p>{homeTeam}</p></div>
      <div className={styles.result__score}>
        <div className={styles.result__info}>
          <p>{competition}</p>
          <p className={styles.result__date}>{matchDate}</p>
        </div>
        <h2>{homeTeamScore} - {awayTeamScore}</h2>
        {comment && <p className={styles.result__comment}>{comment}</p>}
        <p className={styles.result__teams}>{homeTeam} - {awayTeam}</p>
      </div>
      <div className={styles.result__away}><p>{awayTeam}</p></div>
    </div>
  )
}
