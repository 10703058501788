export const ROUTES = {
    home: {
        path: '/',
        title: 'Home',
    },
    elftal: {
        path: '/eerste-elftal',
        title: 'Eerste elftal',
    },
    evenementen: {
        path: '/evenementen',
        title: 'Evenementen',
    },
    sponsors: {
        path: '/sponsors',
        title: 'Sponsors',
    },
    uitslagen: {
        path: '/uitslagen',
        title: 'Uitslagen',
    },
    matchballen: {
        path: '/matchballen',
        title: 'Matchballen',
    },
    over: {
        path: '/over-ons',
        title: 'Over ons',
    },
    contact: {
        path: '/contact',
        title: 'Contact',
    },
};