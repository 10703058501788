import React from 'react'
import styles from './Impression.module.scss'

export default function Impression({ fileName, url, caption }) {
  return (
    <div className={styles.impression}>
        <img src={url} alt={fileName || 'Sfeerbeeld'} />
        { caption ? <p className={styles.caption}>{caption}</p> : null }
    </div>
  )
}
