import React from "react";
// graphql
import { useQuery } from '@apollo/client';
import { GET_PAGE_DATA, GET_FIRST_TEAM_MEMBERS } from '../../graphql/queries';
// pages
import Error from '../Error';
// helmet
import { Helmet } from "react-helmet";
// components
import Loading from '../../components/Elements/Loading';
import TeamMember from "../../components/Basics/TeamMember";
import Impression from "../../components/Basics/Impression";

export default function Elftal() {
  const slug = 'elftal';

  // --------- QUERY FOR THE PAGE DATA --------- //

  const { loading, error, data } = useQuery(GET_PAGE_DATA, {
    variables: { slug },
  });
  const { loading: loadingTeam, error: errorTeam, data: dataTeam } = useQuery(GET_FIRST_TEAM_MEMBERS);
  if (loading || loadingTeam) return <Loading />;
  if (error || errorTeam) return <Error code='502' text='Invalid response. Please try again later.'/>;

  // --------- PAGE DATA --------- //

  const pageData = data.page;
  const heroSection = pageData.heroSection;
  const teamMembers = dataTeam.teamMembers;

  // --------- RENDER --------- //

  return (
    <>
      {/* head data */}
      <Helmet>
        <title>{pageData.title}</title>
        <meta name='description' content={pageData.pageDescription}/>
      </Helmet>

      <div className="container container--top">
        <h1 className='container__header'>Eerste Elftal</h1>
        <div className='container__header'><h3>Ploeg {pageData.season.title}</h3></div>
        <div className='full-team'>
          <Impression url={heroSection.image.url} />
        </div>

        <div className='container__header'><h3>Onze spelerskern</h3></div>
        <div className='team'>
          {teamMembers.map((member, index) => (
            <TeamMember
              key={index}
              fullname={member.fullname}
              position={member.position}
              male={member.male}
              image={member.photo?.url}
            />
          ))}
        </div>
      </div>
    </>
  );
}
