import React from 'react'
import styles from './Hamburger.module.scss'

export default function Hamburger({ menuStatus }) {
  return (
    <button className={styles.menubutton}>
      <svg className={`${styles.hamburger} ${menuStatus === 'open' ? `${styles.open}` : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"><path fill="none" stroke="#2A353F" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2" d="M1 1h24M1 9h24M1 17h24" data-name="Icon ion-menu-outline"/></svg>
      <svg className={`${styles.cross} ${menuStatus === 'open' ? `${styles.open}` : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.1 23.1"><path fill="none" stroke="#2A353F" strokeLinecap="square" strokeWidth="2" d="M21.686 21.686 1.414 1.414m20.272 0L1.414 21.686" data-name="Icon akar-cross"/></svg>
    </button>
  )
}
