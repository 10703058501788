import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './Navigation.module.scss'
import { ROUTES } from '../../../routes';

export default function Navigation({ closeMenu, addClassName = ''}) {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <nav className={`${styles.navigation} ${addClassName}`}>
            <NavLink onClick={() => { closeMenu(); scrollToTop(); }} className={`${styles.navigation__link}`} to={ROUTES.home.path}>{ROUTES.home.title}</NavLink>
            <NavLink onClick={() => { closeMenu(); scrollToTop(); }} className={`${styles.navigation__link}`} to={ROUTES.elftal.path}>{ROUTES.elftal.title}</NavLink>
            <NavLink onClick={() => { closeMenu(); scrollToTop(); }} className={`${styles.navigation__link}`} to={ROUTES.evenementen.path}>{ROUTES.evenementen.title}</NavLink>
            <NavLink onClick={() => { closeMenu(); scrollToTop(); }} className={`${styles.navigation__link}`} to={ROUTES.sponsors.path}>{ROUTES.sponsors.title}</NavLink>
            <NavLink onClick={() => { closeMenu(); scrollToTop(); }} className={`${styles.navigation__link}`} to={ROUTES.uitslagen.path}>{ROUTES.uitslagen.title}</NavLink>
            <NavLink onClick={() => { closeMenu(); scrollToTop(); }} className={`${styles.navigation__link}`} to={ROUTES.matchballen.path}>{ROUTES.matchballen.title}</NavLink>
            <NavLink onClick={() => { closeMenu(); scrollToTop(); }} className={`${styles.navigation__link}`} to={ROUTES.over.path}>{ROUTES.over.title}</NavLink>
            <NavLink onClick={() => { closeMenu(); scrollToTop(); }} className={`${styles.navigation__link}`} to={ROUTES.contact.path}>{ROUTES.contact.title}</NavLink>
        </nav>
    )
}
