import React from 'react'
import styles from './Event.module.scss'

export default function Event({ image, title, description, date }) {
  return (
    <div className={styles.event}>
      <div className={styles.event__info}>
        <h2 className={styles.event__title}>{title}</h2>
        <p className={styles.event__date}>{date}</p>
        <p className={styles.event__description}>{description}</p>
      </div>
      <img src={image} alt={title} className={styles.event__image}/>
    </div>
  )
}