import React from 'react'
import { Link } from 'react-router-dom'

export default function Sponsor({url, image, alt}) {
  return (
    <Link to={url} target='_blank' >
      <img src={image} alt={alt} />
    </Link>
  )
}
