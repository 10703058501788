import React from "react";
import styles from "./AllSponsors.module.scss";
import Sponsor from "../../Basics/Sponsor";

export default function AllSponsors({ sponsors }) {
    return (
        <div className={styles.sponsors}>
            {sponsors.map((sponsor, index) => (
                <Sponsor
                    key={index}
                    url={sponsor.link}
                    image={sponsor.logo.url}
                    alt={sponsor.title}
                />
            ))}
        </div>
    );
}
