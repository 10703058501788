import React from "react";
import fcdLogo from "../../../assets/images/fcd-logo.png";
import styles from "./Loading.module.scss";

export default function Loading() {
    return (
        <div className={styles.loadingcontainer}>
            <div className={styles.spinner}></div>
            <img src={fcdLogo} alt="Logo" className={styles.loadinglogo} />
        </div>
    );
}
