import React from "react";
// graphql
import { useQuery } from "@apollo/client";
import {
  GET_PAGE_DATA,
  GET_ABOUT_US_TEAM_MEMBERS,
} from "../../graphql/queries";
// pages
import Error from "../Error";
// helmet
import { Helmet } from "react-helmet";
// components
import Loading from "../../components/Elements/Loading";
import Impression from "../../components/Basics/Impression";
import TeamMember from "../../components/Basics/TeamMember";
// impressions
import fcd1949 from "../../assets/images/history/fcd-1949.jpeg";
import fcdbestuur from "../../assets/images/history/fcd-bestuur.jpg";
import fcdprehistorie from "../../assets/images/history/fcd-prehistorie.jpg";

export default function Over() {
  const slug = "over-ons";

  // --------- QUERY FOR THE PAGE DATA --------- //

  const { loading, error, data } = useQuery(GET_PAGE_DATA, {
    variables: { slug },
  });
  const {
    loading: loadingTeam,
    error: errorTeam,
    data: dataTeam,
  } = useQuery(GET_ABOUT_US_TEAM_MEMBERS);
  if (loading || loadingTeam) return <Loading />;
  if (error || errorTeam)
    return (
      <Error code="502" text="Invalid response. Please try again later." />
    );

  // --------- PAGE DATA --------- //

  const pageData = data.page;
  const teamMembers = dataTeam.teamMembers;

  // --------- RENDER --------- //

  return (
    <>
      {/* head data */}
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.pageDescription} />
      </Helmet>

      <div className="container container--top">
        <h1 className="container__header">Over ons</h1>

        {/* team (bestuur) */}
        <div className="container__header">
          <h3>Bestuur</h3>
        </div>
        {/* get the team members where category is Bestuur */}
        <div className="team">
          {teamMembers
            .filter((member) => member.category === "Bestuur")
            .map((member, index) => (
              <TeamMember
                key={index}
                fullname={member.fullname}
                position={member.position}
                male={member.male}
                image={member.photo?.url}
              />
            ))}
        </div>

        {/* team (staff) */}
        <div className="container__header">
          <h3>Staff</h3>
        </div>
        {/* get the team members where category is Staff */}
        <div className="team">
          {teamMembers
            .filter((member) => member.category === "Staff")
            .map((member, index) => (
              <TeamMember
                key={index}
                fullname={member.fullname}
                position={member.position}
                male={member.male}
                image={member.photo?.url}
              />
            ))}
        </div>

        {/* team (medewerker) */}
        <div className="container__header">
          <h3>Medewerkers</h3>
        </div>
        {/* get the team members where category is Medewerker */}
        <div className="team">
          {teamMembers
            .filter((member) => member.category === "Medewerker")
            .map((member, index) => (
              <TeamMember
                key={index}
                fullname={member.fullname}
                position={member.position}
                male={member.male}
                image={member.photo?.url}
              />
            ))}
        </div>

        {/* history */}
        <div className="container__header">
          <h3>Historiek</h3>
        </div>
        <p className="paragraph">
          Onder impuls van Jozef Goetvinck, Henri De Dier, Albert Jacobs, Albert
          Lievens, Oscar Meert, Oscar Verleyen, Louis Palsterman, Jean De Wit,
          Henri Vermoesen, Andrea Saeys, Omer Kiekens, Bernard Borms, Frans
          Ebraert, Frans Van Mol e.a. werd FC Doggen gesticht in 1949 bij Oscar
          Meert, aan De Kleistraat, rechtover 't Vissershof. De Ploeg werd
          genoemd naar het cafe "In de Doghond', waar de club gesticht werd.
          Gebouwen die werden onteigend in 1952 omwille v.d. aanleg v.d.
          autosnelweg.
        </p>
        <Impression
          fileName="fcd-1949"
          url={fcd1949}
          caption="Bij de foto: Doggen Erembodegem - in 1949. Geknield vinr: Frans Suys, Louis Van Hoefs, Marcel Callebaut, Prosper Van Eyghen en Pierre Moens.
Stand vin: Maurice Callebaut, Antoine Ledegen, Frans Meert, Frans Verdoodt, Petrus Van hoefs en Etienne Goetvinck (Louis Van Hoefs)"
        />
        <p className="paragraph">
          Tussen 1949 en 1959 werden de Doggen twee keer kampioen in de
          Koninklijke Belgische Liefhebbersbond.
        </p>
        <p className="paragraph">
          Na het Overlijden van voorzitter Bernard Borms namen Frans Ebraert
          (als voorzitter) en Frans Van Mol (als Secretaris Penningmeester) het
          roer over en wwerd er overgeschakeld naar het Katholiek Sportverbond,
          waar ze alsgemeente aan de rand van Oost-vlaanderen samen met
          eendracht Meldert toelating kregen aan te sluiten bij de Brabantse
          afdeling. FC Doggen veroverde op de oude terreinen aan de Stillebeek
          de Kampioenstitel in 1957 - 1958.
        </p>
        <p className="paragraph">
          Omwille van het verdwijnen van Meldert en ploegen uit Teralfene, uit
          deze federatie zodat er geen interessante derby's meer waren, stapte
          ook FC Doggen over naar de KBV (Koninklijke Belgische Voetbalbond). In
          1971 vond men een gepast terrein op Ressebeke waar FC Doggen kampioen
          werd in 4de provinciale afdeling. Trainer Robert D'haese Technisch
          raadgever Gustaaf De Greve en een kern van gemotiveerde spelers hadden
          zich hiervoor tenvolle ingezet.
        </p>
        <p className="paragraph">
          Mits ook transfers zwaar wogen op het budget van de clubkas werd er
          geopteerd voor opleiding van eigen jeugd, dit vooral onder impuls van
          Raymond Jacobs, Guido Goetvinck, Kamiel De Gendt, Jozef De Gendt,
          Roger Jacobs, Gustaaf Troch, Jeroom De Cuyper en toenmalig
          jeugdtrainer Pierre Kempeneers. ​FC Doggen speelde dan ook met liefst
          zes ploegen: miniemen, kadetten, scholieren, juniores, reserven en een
          fanionteam. Men moest dus uiykijken naar een tweede terrein omwille
          van de overbelasting. De verjongingskuur bracht niet direct de
          kentering, er werd meer de staart gespeeld dan de kop. Wel werd
          ondertussen de accommodatie fel verbeterd, de omheining, de parking,
          het afdak, douches... ook de kantine was aan een grondige vernieuwing
          toe.
        </p>
        <Impression
          fileName="fcd-bestuur"
          url={fcdbestuur}
          caption="Oud bestuur van FC DOGGEN. Staande van links naar rechts: Omer Kiekens, Frans Ebraert, Frans Van Oudenhove, Frans Van Mol. Zittend van links naar rechts: Willy Goetvinck, Kaliel De Gendt, François Iemants, Gustaaf De Greve."
        />
        <p className="paragraph">
          De club telt circa 150leden inclusief het bestuur en spelers. jaren
          tachtig bestond het bestuur uit Omer Baeyens, (erevoorzitter) Willy
          Goetvinck,(voorzitter), Walter D'hauwer (ondervoorzitter) Frans van
          Mol (secretaris-generaal en penningmeester) Guido Goetvinck
          (jeugdsecretaris), veder Karel Pieters, Gustaaf De Greve, Jaak Van
          Herreweghe, Heri Roelandt, Jeroom De Cuyper, Gustaaf Troch, Roger
          Jacobs, Remi Herman, Roger De Backer, Omer Kiekens, en Michel Sonck.
        </p>
        <p className="paragraph">
          Rond die periode waren Frans De Greve, Karel Pieters, en J.P.
          Clocheret de trainers.
        </p>
        <Impression
          fileName="fcd-prehistorie"
          url={fcdprehistorie}
          caption={`Een foto uit de “prehistorie" (tussen 1945 en 1949) van FC Doggen, kort na de tweede wereldoorlog, schrijft Antoine Ledegen. Wie herkent de spelers nog? Zittend van links af: Etienne Goetvinck, Roger Moens, Louis van Hoefs, Frans Suys en Pierre Moens.
Staande van links af: Marcel Pels, Frans Meert, Antoine Ledegen, Rufin Vinck, Petrus Van Hoers en Frans Van Den Berghe.`}
        />
      </div>
    </>
  );
}
