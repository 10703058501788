import React from 'react'
import styles from './Redirect.module.scss'
import { Link } from 'react-router-dom'

export default function Redirect({ label, link, target }) {
  return (
    <Link className={styles.redirect} to={link} target={target}>
      <span className={styles.redirect__label}>{label}</span>
    </Link>
  )
}
