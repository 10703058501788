import React from 'react'
// graphql
import { useQuery } from '@apollo/client';
import { GET_PAGE_DATA, GET_ALL_SPONSORS } from '../../graphql/queries';
// pages
import Error from '../Error';
// helmet
import { Helmet } from 'react-helmet';
// components
import Loading from '../../components/Elements/Loading';
import AllSponsors from '../../components/Elements/AllSponsors';

export default function Sponsors() {
  const slug = 'sponsors';

  // --------- QUERY FOR THE PAGE DATA --------- //

  const { loading, error, data } = useQuery(GET_PAGE_DATA, {
    variables: { slug },
  });
  const { loading: loadingSponsors, error: errorSponsors, data: dataSponsors } = useQuery(GET_ALL_SPONSORS);
  if (loading || loadingSponsors) return <Loading />;
  if (error || errorSponsors) return <Error code='502' text='Invalid response. Please try again later.'/>;

  // --------- PAGE DATA --------- //

  const pageData = data.page;
  const sponsors = dataSponsors.sponsors;

  // --------- RENDER --------- //

  return (
    <>
      {/* head data */}
      <Helmet>
        <title>{pageData.title}</title>
        <meta name='description' content={pageData.pageDescription}/>
      </Helmet>

      {/* all the sponsors */}
      <div className='container container--top'>
        <h1 className='container__header'>Sponsors</h1>
        <AllSponsors sponsors={sponsors} />
      </div>
    </>
  )
}
