import { gql } from "@apollo/client";

// To get all the data for a page
const GET_PAGE_DATA = gql`
  query GET_PAGE_DATA($slug: String = "") {
    page(where: { slug: $slug }) {
      title
      pageDescription
      heroSection {
        title
        subtitle
        image {
          fileName
          url
        }
      }
      season {
        title
        slug
      }
    }
  }
`;

// To get all the sponsors
const GET_ALL_SPONSORS = gql`
  query GET_ALL_SPONSORS {
    sponsors(first: 50, orderBy: sortOrder_ASC) {
      title
      link
      logo {
        url
      }
    }
  }
`;

// To get the first 4 sponsors
const GET_FIRST_FOUR_SPONSORS = gql`
  query GET_FIRST_FOUR_SPONSORS {
    sponsors(first: 4, orderBy: sortOrder_ASC) {
      title
      link
      logo {
        url
      }
    }
  }
`;

// To get the last season match results and upcoming matches
const GET_SEASON_RESULTS = gql`
  query GET_SEASON_RESULTS($seasonSlug: String = "") {
    results(
      last: 50
      orderBy: matchDate_ASC
      where: { season: { slug: $seasonSlug } }
    ) {
      id
      homeTeam {
        title
      }
      awayTeam {
        title
      }
      homeTeamScore
      awayTeamScore
      matchDate
      competition {
        title
      }
      comment
    }
  }
`;

// To get the last result of a played match in a season
const GET_LAST_RESULT = gql`
  query GET_LAST_RESULT {
    results(
      last: 1
      orderBy: matchDate_ASC
      where: { homeTeamScore_not: null }
    ) {
      homeTeam {
        title
      }
      awayTeam {
        title
      }
      homeTeamScore
      awayTeamScore
      matchDate
      competition {
        title
      }
      comment
    }
  }
`;

// To get the season ranking
const GET_SEASON_RANKING = gql`
  query GET_SEASON_RANKING($seasonSlug: String = "") {
    rankings(where: { season: { slug: $seasonSlug } }) {
      ranking
      playedMatches
      won
      draw
      lost
      points
      goalsFor
      goalsAgainst
      goalSaldo
      link
    }
  }
`;

// To get all the events
const GET_ALL_EVENTS = gql`
  query GET_ALL_EVENTS {
    events(first: 10, orderBy: dateEvent_ASC) {
      title
      image {
        url
        fileName
      }
      description
      dateDescription
    }
  }
`;

// To get the next upcoming event
const GET_NEXT_UPCOMING_EVENT = gql`
  query GET_NEXT_UPCOMING_EVENT($todayDate: DateTime!) {
    events(
      where: { dateEvent_gte: $todayDate }
      orderBy: dateEvent_ASC
      first: 1
    ) {
      title
      image {
        url
        fileName
      }
      description
      dateDescription
    }
  }
`;

// To get the about us team members
const GET_ABOUT_US_TEAM_MEMBERS = gql`
  query GET_ABOUT_US_TEAM_MEMBERS {
    teamMembers(first: 25) {
      fullname
      male
      position
      category
      photo {
        url
      }
    }
  }
`;

// To get all the members in the first team
const GET_FIRST_TEAM_MEMBERS = gql`
  query GET_FIRST_TEAM_MEMBERS {
    teamMembers(
      first: 35
      where: { category: "Elftal" }
      orderBy: fullname_ASC
    ) {
      fullname
      male
      position
      photo {
        url
      }
    }
  }
`;

// To get all the matchballs
const GET_ALL_MATCHBALLS = gql`
  query GET_ALL_MATCHBALLS {
    matchballs(orderBy: fixtureDate_ASC, first: 25) {
      matchballImage {
        url
      }
    }
  }
`;

export {
  GET_PAGE_DATA,
  GET_ALL_SPONSORS,
  GET_FIRST_FOUR_SPONSORS,
  GET_SEASON_RESULTS,
  GET_LAST_RESULT,
  GET_SEASON_RANKING,
  GET_ALL_EVENTS,
  GET_NEXT_UPCOMING_EVENT,
  GET_ABOUT_US_TEAM_MEMBERS,
  GET_FIRST_TEAM_MEMBERS,
  GET_ALL_MATCHBALLS,
};
