import './styles/reset.css'
import './styles/App.scss';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, } from "react-router-dom";
import { ROUTES } from "./routes";
import Root from "./layouts/Root";
import { Home, Elftal, Evenementen, Sponsors, Uitslagen, Contact, Over, Error, Matchballen } from "./pages";

const router = createBrowserRouter(createRoutesFromElements(
  <Route path="/" element={<Root />} errorElement={<Error />}>
    <Route path={ROUTES.home.path} element={<Home />} />
    <Route path={ROUTES.elftal.path} element={<Elftal />} />
    <Route path={ROUTES.evenementen.path} element={<Evenementen />} />
    <Route path={ROUTES.sponsors.path} element={<Sponsors />} />
    <Route path={ROUTES.uitslagen.path} element={<Uitslagen />} />
    <Route path={ROUTES.matchballen.path} element={<Matchballen />} />
    <Route path={ROUTES.over.path} element={<Over />} />
    <Route path={ROUTES.contact.path} element={<Contact />} />
  </Route>
));

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
