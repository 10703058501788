import React from 'react'
import styles from './Logo.module.scss'
import FCDLogo from '../../../assets/images/fcd-logo.png'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../routes'

export default function Logo() {
  return (
    <Link to={ROUTES.home.path} className={styles.logo}>
      <img src={FCDLogo} alt="FC Doggen logo" />
    </Link>
  )
}
